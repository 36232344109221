import React from 'react';
import _ from 'lodash';

import {buildPreviewData, findElementById} from '../../../helpers';

const SharedOverview = ({filteredData, setup, assets = []}) => {
  const stateColorCoding = {
    Draft: '#afafaf',
    'In review': '#feca57',
    Approved: '#1dd1a1',
    Rejected: '#ff6b6b',
    'On hold': '#fecec6',
  };

  return (
    <div id="paginated-list">
      {filteredData.map(ad => {
        const columnsUsedInPreview = Object.entries(setup.adColumns)
          .filter(([, v]) => {
            if (v.includeInManifest) {
              return true;
            }
            return false;
          })
          .map(([k]) => k);
        const sizes = setup.sizes.map((size, index) => {
          const file = assets?.files?.[size.assetId];
          const currentSize = ad.shots
            .map(s => findElementById(s.shotSizes, size._id))
            .filter(s => s);
          if (_.isEmpty(currentSize) || !file) {
            return [];
          }

          return buildPreviewData(
            currentSize,
            file,
            setup.shotStructure,
            size._id,
            columnsUsedInPreview,
            ad.columns
          );
        });

        return (
          <div
            style={{
              borderBottom: '2px solid #afafaf',
              paddingBottom: '1rem',
              width: '80%',
              margin: 'auto',
              marginBottom: '1rem',
            }}
            className="paginated-item"
          >
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <h3 style={{fontWeight: 'semibold'}} className="font-weight-semibold">
                {ad.columns['Ad Name'] || ''}{' '}
              </h3>
              <span
                style={{
                  color: stateColorCoding[ad?.columns?.State],
                  fontWeight: 'bold',
                  marginLeft: '1rem',
                }}
              >
                <span>{`\u2022`} </span>
                {ad?.columns?.State}
              </span>
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '1rem',
                columnGap: '2rem',
                justifyContent: 'center',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              {sizes.map(size => {
                const currentSize = ad.sizes.find(s => s._id === size.sizeId);
                if (!currentSize || !currentSize.enabled) {
                  return null;
                }

                return (
                  <div className="d-flex flex-column iframe" key={size.size}>
                    <iframe
                      title={size.size}
                      loading="lazy"
                      src={`${process.env.REACT_APP_S3_URL}/${size.asset}?${size.src}`}
                      id={`${size.size}`}
                      width={currentSize.width}
                      height={currentSize.height}
                      frameBorder="0"
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: '0.3rem',
                        cursor: 'pointer',
                      }}
                    >
                      <span role="button" className="reload">{`\u21BB`}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SharedOverview;
