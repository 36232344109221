import React, {useMemo, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getFiles} from '../../pages/file/slice';
import api from '../../api';

const VideoConverterLoaderButton = ({creative, version, currentVersion}) => {
  const isBtnVisible = useMemo(
    () =>
      currentVersion.files.includes('/grid8player.js') ||
      currentVersion.files?.includes('/grid8player.min.js'),
    [currentVersion.files]
  );
  const params = useParams();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  if (!isBtnVisible) {
    return null;
  }

  const handleVideoConvert = async () => {
    setIsLoading(true);

    try {
      const result = await api.asset.videoConverter(creative._id, version);
      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
        };
        await dispatch(getFiles({type: 'brand', id: params.id, filter}));
      }
    } catch (error) {
      console.log('video converter error', error);
    }
    setIsLoading(false);
  };

  return (
    <Button
      disabled={isLoading}
      type="button"
      onClick={handleVideoConvert}
      className="px-3 py-1 mb-4 loader-button white video-converter-btn"
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon="circle"
          color="blue"
          style={{height: '26px', width: '26px', color: '#ff6b6b'}}
          className="mr-4"
        />
        <p className="m-0 p-0 text-danger">{isLoading ? 'LOADING...' : 'RECORD'}</p>
      </div>
    </Button>
  );
};

export default VideoConverterLoaderButton;
