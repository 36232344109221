/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-expressions */
import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';

import {Overlay, Tooltip} from 'react-bootstrap';

import {TablePreview} from './components/Previews';
import {defaultColDef} from '../consts';
import {publishAds, unpublishAds} from '../slice';
import {PublishModal} from './components/Modals';
import useLoaderButton from '../../../../hooks/useLoaderButton';
import {LoaderButton} from '../../../../components/LoaderButton';
import Switch from '../../../../components/Switch';
import TooltipHelper from '../../../../helpers/tooltip';
import {agHelpers, publishAgGridColumns} from './agHelpers';
import {findElementById} from '../helpers';
import {copyToClipboard} from '../../../../helpers/copyToClipboard';
import Access from '../../../../helpers/access';
import ExportModal from './components/Modals/ExportModal';

const Publish = ({ad, dispatch, parents, setup, assets, setCurrentAd}, ref) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState();
  const [showExportModal, setShowExportModal] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const [
    loaderButtonStatus,
    setLoaderButtonCompleted,
    setLoaderButtonError,
    setLoaderButtonLoading,
    setLoaderButtonDefault,
  ] = useLoaderButton();

  const [
    loaderButtonStatusUnpublish,
    setLoaderButtonCompletedUnpublish,
    setLoaderButtonErrorUnpublish,
    setLoaderButtonLoadingUnpublish,
    setLoaderButtonDefaultUnpublish,
  ] = useLoaderButton();

  const gridRef = useRef();
  const refInfo = useRef(null);

  const {reloadTable, setColumnWidth, getSelectedRows} = useMemo(() => agHelpers(gridRef), [
    gridRef,
  ]);
  const activeAds = useMemo(
    () =>
      showAll
        ? ad.items.filter(innerAd => innerAd?.published?.csv)
        : ad.items.filter(innerAd => innerAd?.columns?.State === 'Approved'),
    [ad.items, showAll]
  );
  const columnDefs = useMemo(() => publishAgGridColumns(), []);

  useImperativeHandle(ref, () => ({
    getData: () => selectedRows,
  }));

  const handleSelectedChange = useCallback(() => {
    setSelectedRows(getSelectedRows());
    setLoaderButtonDefault();
    setLoaderButtonDefaultUnpublish();
  }, [setLoaderButtonDefault, setLoaderButtonDefaultUnpublish, getSelectedRows]);

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleLoaderButtonActions = useCallback(
    (button, action) => {
      switch (action) {
        case 'loading':
          button === 'Publish' ? setLoaderButtonLoading() : setLoaderButtonLoadingUnpublish();
          break;
        case 'completed':
          button === 'Publish' ? setLoaderButtonCompleted() : setLoaderButtonCompletedUnpublish();
          break;
        case 'error':
          button === 'Publish' ? setLoaderButtonError() : setLoaderButtonErrorUnpublish();
          break;
        default:
          button === 'Publish' ? setLoaderButtonDefault : setLoaderButtonDefaultUnpublish();
      }
    },
    [
      setLoaderButtonCompleted,
      setLoaderButtonCompletedUnpublish,
      setLoaderButtonDefault,
      setLoaderButtonDefaultUnpublish,
      setLoaderButtonError,
      setLoaderButtonErrorUnpublish,
      setLoaderButtonLoading,
      setLoaderButtonLoadingUnpublish,
    ]
  );

  const handleAction = useCallback(
    async action => {
      handleLoaderButtonActions(action, 'loading');
      try {
        const requestParams = {
          ids: selectedRows.map(row => row._id),
          parents,
        };
        const {payload: data} = await dispatch(
          action === 'Publish' ? publishAds(requestParams) : unpublishAds(requestParams)
        );

        setCurrentAd(old => {
          const currentAd = findElementById(data, old?._id);
          if (currentAd) {
            return {
              ...old,
              columns: {...old.columns, State: currentAd.columns.State},
            };
          }
          return old;
        });

        setSelectedRows([]);
        reloadTable();
        setShowModal(true);
        handleLoaderButtonActions(action, 'completed');
      } catch (error) {
        handleLoaderButtonActions(action, 'error');
      }
    },
    [selectedRows, reloadTable, dispatch, parents, setCurrentAd, handleLoaderButtonActions]
  );

  const handleShowAllChange = useCallback(value => {
    setShowAll(value);
  }, []);

  const handlePublishUrlClicked = () => {
    if (setup && setup._id) {
      copyToClipboard(`https://${process.env.REACT_APP_API_DOMAIN}/api/dco/feed/${setup._id}`);
      setShowInfo(true);
      setTimeout(() => {
        setShowInfo(false);
      }, 3000);
    }
  };

  return (
    <div>
      <span className="dco-publish-gradient" />
      <p style={{fontSize: '1.25rem', fontWeight: 'bold'}}>All these ads are good to go!</p>
      <p style={{fontSize: '1rem', fontWeight: 'semibold'}}>Select all ads you want to publish</p>

      <div className="d-flex mb-4 align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          <Access type="dco" action="edit" parents={parents}>
            {() => (
              <>
                <LoaderButton
                  className="mr-3"
                  defaultText={`Publish ${selectedRows.length} ads`}
                  disabled={showAll || !selectedRows.length}
                  loadingText="Publishing..."
                  loaderButtonStatus={loaderButtonStatus}
                  classes={showAll || !selectedRows.length ? 'opacity-25' : ''}
                  completedText="Published!"
                  onClick={() => handleAction('Publish')}
                />
                <LoaderButton
                  className="mr-3"
                  defaultText={`Unpublish ${selectedRows.length} ads`}
                  disabled={!selectedRows.length}
                  loadingText="Unpublishing..."
                  defaultColor="gray"
                  classes={!selectedRows.length ? 'opacity-25' : ''}
                  loaderButtonStatus={loaderButtonStatusUnpublish}
                  completedText="Unpublished!"
                  onClick={() => handleAction('unPublish')}
                />
              </>
            )}
          </Access>

          <div className="d-flex align-items-center">
            <TooltipHelper placement="top-start" content="This will disable publishing of ads">
              <span className="mr-2">Show all ads in feed</span>
            </TooltipHelper>
            <Switch checked={showAll} handleChange={checked => handleShowAllChange(checked)} />
          </div>

          <div>
            {selectedRows.length > 0 ? (
              <button
                className="dco-button primary"
                href="#"
                onClick={() => {
                  console.log(selectedRows);
                  setShowExportModal(true);
                }}
              >
                Bulk Export
              </button>
            ) : null}{' '}
          </div>
        </div>
        <div className="mr-4">
          <Overlay target={refInfo?.current} show={showInfo} placement="top">
            {overlayProps => (
              <Tooltip id="overlay-example" {...overlayProps}>
                <span className="px-2">Feed url copied successfully</span>
              </Tooltip>
            )}
          </Overlay>
          <span
            className="icon icon-chain"
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#E5E5E5',
            }}
            role="button"
            tabIndex={0}
            ref={refInfo}
            onClick={handlePublishUrlClicked}
          />
        </div>
      </div>
      <div className="ag-theme-alpine banner">
        <AgGridReact
          masterDetail
          ref={gridRef}
          rowData={activeAds || []}
          rowHeight={64}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onRowSelected={handleSelectedChange}
          detailCellRenderer={TablePreview}
          detailCellRendererParams={{setup, assets, parents}}
          suppressRowClickSelection
          suppressRowTransform
          suppressContextMenu
          detailRowAutoHeight
          rowSelection="multiple"
          domLayout="autoHeight"
          onGridReady={onGridReady}
        />
      </div>
      <PublishModal
        when={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        setup={setup}
        parents={parents}
      />
      <ExportModal
        show={showExportModal}
        onClose={() => {
          setShowExportModal(false);
        }}
        ads={selectedRows}
        setup={setup}
        parents={parents}
      />
    </div>
  );
};

export default connect(
  store => ({
    ad: store.ad,
  }),
  null,
  null,
  {forwardRef: true}
)(forwardRef(Publish));
