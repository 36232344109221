/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';

import api from '../../../../../../api';
import {buildPreviewData, buildURLQuery} from 'pages/project/DcoProject/helpers';

const ExportModal = props => {
  const {show, onClose = () => {}, ads, setup, parents} = props;
  const [toExport, setToExport] = useState({});
  const [settings, setSettings] = useState();

  const adAndSizeIdToQ2a = (ad, sizeId) => {
    const columnsUsedInPreview = [];
    // eslint-disable-next-line no-restricted-syntax
    console.log(setup.adColumns);
    for (const [key, value] of Object.entries(setup.adColumns)) {
      if (value?.includeInManifest) {
        columnsUsedInPreview.push(key);
      }
    }

    const shots4size = ad.shots
      .map(shot => shot.shotSizes.map(sh => sh[sizeId]).filter(s => !!s))
      .filter(s => s.length);

    const urlParams = buildURLQuery(
      shots4size.flat(),
      setup.shotStructure,
      columnsUsedInPreview,
      ad.columns
    );
    return urlParams;
  };

  const bulkExport = () => {
    console.log('export all these in bulk', toExport);
    api.asset.bulkVideoConverter({batch: toExport, parents});
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header style={{justifyContent: 'end', borderBottom: '0'}}>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        select sizes to export here:
        {ads.map(ad => {
          console.log('inspect ad', ad);
          return (
            <p>
              {ad.columns['Ad Name']}:{' '}
              {ad?.sizes.map((size, si) => {
                const sizeKey = `${ad.columns['Ad Name']}_${size.width}x${size.height}_${si}`;
                return (
                  <p
                    style={{
                      backgroundColor: '#ffffff',
                      border: '2px solid #afafaf ',
                      borderRadius: '2rem',
                      padding: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={_ =>
                      setToExport(e => {
                        const newE = {...e};
                        newE[sizeKey] = !e[sizeKey]
                          ? {q2a: adAndSizeIdToQ2a(ad, size._id), assetId: size.assetId}
                          : undefined;
                        return newE;
                      })
                    }
                  >
                    {size.width}x{size.height}{' '}
                    {toExport[sizeKey] ? (
                      <span className="icon icon-check-green x32" />
                    ) : (
                      <span
                        className="icon icon-x x16"
                        style={{backgroundColor: 'red', margin: 8}}
                      />
                    )}
                    {/*adAndSizeIdToQ2a(ad, size._id)*/}
                  </p>
                );
              })}
            </p>
          );
        })}
        <button className="btn btn-primary" style={{borderRadius: '2rem'}} onClick={bulkExport}>
          exort
        </button>
      </Modal.Body>
    </Modal>
  );
};
export default ExportModal;
