// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".queueRow_hvr__NYa5z:hover {\n  color: #21d1a1;\n  filter: invert(58%) sepia(71%) saturate(473%) hue-rotate(113deg) brightness(102%) contrast(86%);\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/project/queueRow.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+FAA+F;EAC/F,eAAe;AACjB","sourcesContent":[".hvr:hover {\n  color: #21d1a1;\n  filter: invert(58%) sepia(71%) saturate(473%) hue-rotate(113deg) brightness(102%) contrast(86%);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hvr": "queueRow_hvr__NYa5z"
};
export default ___CSS_LOADER_EXPORT___;
