// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner_container__kG1dQ {\n  padding: 1rem 1rem 1rem 1rem;\n  text-align: center;\n}\n\n.spinner_spinner__tBfAV {\n  color: rgb(229, 231, 235);\n  height: 50px;\n  width: 50px;\n  fill: #1c64f2;\n}\n\n.spinner_animation-spin__gv--O {\n  animation: spinner_spin__h9JDS 2s infinite linear;\n}\n@keyframes spinner_spin__h9JDS {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(359deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/project/spinner.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;AACf;;AAEA;EAEE,iDAAkC;AACpC;AACA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  padding: 1rem 1rem 1rem 1rem;\n  text-align: center;\n}\n\n.spinner {\n  color: rgb(229, 231, 235);\n  height: 50px;\n  width: 50px;\n  fill: #1c64f2;\n}\n\n.animation-spin {\n  -webkit-animation: spin 2s infinite linear;\n  animation: spin 2s infinite linear;\n}\n@keyframes spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(359deg);\n    transform: rotate(359deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "spinner_container__kG1dQ",
	"spinner": "spinner_spinner__tBfAV",
	"animation-spin": "spinner_animation-spin__gv--O",
	"spin": "spinner_spin__h9JDS"
};
export default ___CSS_LOADER_EXPORT___;
