import axios from 'axios';

const useLocalhostForSpecificEndPoint = false;
class Api {
  constructor() {
    if (!process.env.REACT_APP_ENV) throw new Error('Something is wrong!');

    axios.defaults.baseURL = `https://${process.env.REACT_APP_API_DOMAIN}/api`;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('access-token')}`;
      config.headers.accept = 'application/json';

      return config;
    });
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          axios.defaults.headers.Authorization = '';
          localStorage.removeItem('access-token');
        }
        return Promise.reject(error);
      }
    );
  }

  auth = {
    login: form => axios.post('/auth/login', form),
    logout: () => axios.delete('/auth/logout'),
    registration: form => axios.post('/auth/registration', form),
    getUser: () => axios.get('/auth/token'),
  };

  user = {
    getUsers: params => axios.get('/auth/user/', {params}),
    getUsersBasic: params => axios.get('/auth/user/basic', {params}),
    findByIds: params => axios.get('/auth/user/findByIds', {params}),
    getUser: id => axios.get(`/auth/user/${id}`),
    getUserBasic: id => axios.get(`/auth/user/${id}/basic`),
    getPublicUser: id => axios.get(`/auth/publicUser/${id}`),
    createUser: form => axios.post('/auth/user', form),
    updateUser: (id, form) => axios.put(`/auth/user/${id}`, form),
    updateUserPass: (id, form) => axios.put(`/auth/user/${id}/password`, form),
    deleteUser: id => axios.delete(`/auth/user/${id}`),

    getUsersForMention: (type, id) => axios.get(`/auth/access/${type}/${id}/users`),
    getAccesses: (type, id) => axios.get(`/auth/access/${type}/${id}`),
    grandAccess: (type, id, form) => axios.post(`/auth/access/${type}/${id}`, form),
    updateAccess: (id, form) => axios.put(`/auth/access/${id}`, form),
    deleteAccess: id => axios.delete(`/auth/access/${id}`),
  };

  brand = {
    getBrands: params => axios.get('/corp/brand/', {params}),
    findByIds: params => axios.get('/corp/brand/findByIds', {params}),
    getBrand: id => axios.get(`/corp/brand/${id}`),
    createBrand: form => axios.post('/corp/brand', {...form}),
    updateBrand: (id, form) => axios.put(`/corp/brand/${id}`, {...form}),
    // updateNote: (id, form) => axios.put(`/corp/brand/${id}/note`, form),
    // updateName: (id, form) => axios.put(`/corp/brand/${id}/name`, form),
    // updateDCM: (id, form) => axios.put(`/corp/brand/${id}/DCM`, form),
    // updateAdAccounts: (id, form) => axios.put(`/corp/brand/${id}/adAccount`, form),
    deleteBrand: id => axios.delete(`/corp/brand/${id}`),
  };

  team = {
    getTeams: params => axios.get('/corp/team/', {params}),
    getTeam: id => axios.get(`/corp/team/${id}`),
    createTeam: form => axios.post('/corp/team', form),
    update: (id, form) => axios.put(`/corp/team/${id}`, form),
    // updateName: (id, form) => axios.put(`/corp/team/${id}/name`, form),
    deleteTeam: id => axios.delete(`/corp/team/${id}`),

    addPage: (teamId, form) => axios.post(`/corp/team/${teamId}/pages`, form),
    updatePage: (teamId, id, form) => axios.put(`/corp/team/${teamId}/pages/${id}`, form),
    deletePage: (teamId, id) => axios.delete(`/corp/team/${teamId}/pages/${id}`),
  };

  videobuilder = {
    listTemplates: teamId => axios.get(`/videobuilder/templates/list/${teamId}`),
    getTemplateByName: name => axios.get(`/videobuilder/templates/getByName/${name}`),
    uploadTemplate: (formData, func) =>
      axios.post(`/videobuilder/templates/add`, formData, {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: progressEvent =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    submitRend: data => axios.post(`/videobuilder/worker/task`, data),
    getQueue: data =>
      axios.get(`/videobuilder/nexrender/q?brandId=${data.brandId}&projectId=${data.projectId}`),
    setRendPriority: (uid, priority) =>
      axios.put(`/videobuilder/nexrender/api/v1/jobs/${uid}`, {priority}),
    restartJob: uid => axios.put(`/videobuilder/nexrender/api/v1/jobs/${uid}`, {state: 'queued'}),
    deleteJob: uid => axios.delete(`/videobuilder/nexrender/api/v1/jobs/${uid}`),
    saveSettings: data => axios.post(`/videobuilder/templates/saveSettings`, data),
    loadSettings: (templateId, projectId) =>
      axios.get(`/videobuilder/templates/loadSettings/${templateId}/${projectId}`),
    captureLiveVideo: data => axios.post(`/videobuilder/tools/convertLiveStreamToMp4`, data),
    bulkModeFlip: templateId => axios.post(`/videobuilder/templates/bulkModeFlip/${templateId}`),
  };

  project = {
    getProjects: params => axios.get('/corp/project', {params}),
    getProject: id => axios.get(`/corp/project/${id}`),
    createProject: form => axios.post('/corp/project', {...form}),
    updateProject: (id, form) => axios.put(`/corp/project/${id}`, {...form}),
    // updateStatus: (id, form) => axios.put(`/corp/project/${id}/status`, { ...form }),
    deleteProject: id => axios.delete(`/corp/project/${id}`),
  };

  asset = {
    getFiles: (type, id, params) => axios.get(`/asset/${type}/${id}`, {params}),
    getFile: (id, params) => axios.get(`/asset/${id}`, {params}),
    uploadFile: (type, id, formData, func) =>
      axios.post(`/asset/${type}/${id}`, formData, {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: progressEvent =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    uploadAvatar: (type, id, formData) =>
      axios.post(`/asset/avatar/${type}/${id}`, formData, {
        headers: {'content-type': 'multipart/form-data'},
      }),
    findAvatars: (type, ids) => axios.get(`/asset/avatar/${type}`, {params: {ids}}),
    addLink: (type, id, form) => axios.post(`/asset/${type}/${id}/url`, form),
    uploadDco: (type, id, formData, func) =>
      axios.post(`/asset/${type}/${id}/dco`, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    uploadCreativeSetup: (id, formData) =>
      axios.post(`/asset/brand/${id}/creativeSetup`, formData, {
        headers: {'content-type': 'multipart/form-data'},
      }),
    updateCreativeSetup: (id, formData) =>
      axios.put(`/asset/${id}/creativeSetup`, formData, {
        headers: {'content-type': 'multipart/form-data'},
      }),
    uploadCreative: (type, id, formData, func) =>
      axios.post(`/asset/${type}/${id}/creative`, formData, {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: progressEvent =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    updateCreative: (id, formData, func) =>
      axios.put(`/asset/${id}/creative`, formData, {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: progressEvent =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    deleteAsset: id => axios.delete(`/asset/${id}`),
    getColorGrouos: brandId => axios.get(`/asset/colorGroup/${brandId}`),
    createColorGroup: (brandId, form) => axios.post(`/asset/colorGroup/${brandId}`, form),
    updateColorGroup: (groupId, form) => axios.put(`/asset/colorGroup/${groupId}`, form),
    deleteColorGroup: groupId => axios.delete(`/asset/colorGroup/${groupId}`),
    addColor: (groupId, form) => axios.post(`/asset/color/${groupId}`, form),
    updateColor: (groupId, colorId, form) => axios.put(`/asset/color/${groupId}/${colorId}`, form),
    deleteColor: (groupId, colorId) => axios.delete(`/asset/color/${groupId}/${colorId}`),
    videoConverter: (assetId, version, frame = 'empty') => {
      return axios.get(`/tools/video/fromCreative/${assetId}/${version}/${frame}`);
    },
    videoConverterWithQueryParams: (
      assetId,
      version,
      queryToAppend = '',
      frame = 'empty',
      parents,
      nameoverride,
      audio,
      isGif,
      gifFps,
      gifFrames
    ) => {
      return axios.post(`/tools/video/fromCreative/${assetId}/${version}/${frame}`, {
        queryToAppend,
        parents,
        nameoverride,
        audio,
        isGif,
        gifFps,
        gifFrames,
      });
    },
    bulkVideoConverter: options => {
      return axios.post(`/tools/video/fromCreatives`, options);
    },
    getDeletedFiles: (type, id, params) => axios.get(`/asset/trash/${type}/${id}`, {params}),
    permaDeleteAllDeletedAssets: (type, id) => axios.delete(`/asset/trash/${type}/${id}`),
    restoreAllDeletedAssets: (type, id) => axios.post(`/asset/trash/${type}/${id}`),
    restoreDeletedAssets: (ids, parents) => axios.post(`/asset/trash`, {ids, parents}),
    premaDeletedAssets: (ids, parents) => axios.delete(`/asset/trash`, {data: {ids, parents}}),
  };

  comment = {
    getComments: (type, id, params) => axios.get(`/comment/${type}/${id}`, {params}),
    createComment: (type, id, form) => axios.post(`/comment/${type}/${id}`, form),
    uploadAttachment: (type, id, formData) =>
      axios.put(`/comment/${type}/${id}`, formData, {
        headers: {'content-type': 'multipart/form-data'},
      }),
    updateComment: (id, form) => axios.put(`/comment/${id}`, {...form}),
    resolve: id => axios.put(`/comment/${id}/resolve`, {}),
    deleteComment: id => axios.delete(`/comment/${id}`),
    deleteAttachment: (id, tmpKey) => axios.delete(`/comment/attachment/${id}`, {data: {tmpKey}}),
  };

  dpa = {
    getFeeds: parents =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.get(
            `http://localhost:3030/api/dpa/feeds/${parents.brandId}/${parents.teamId}/${parents.projectId}`
          )
        : axios.get(`/dpa/feeds/${parents.brandId}/${parents.teamId}/${parents.projectId}`),
    getFeed: feedId =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.get(`http://localhost:3030/api/dpa/feeds/${feedId}`)
        : axios.get(`/dpa/feeds/${feedId}`),
    addFeed: data =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.post('http://localhost:3030/api/dpa/feeds/add', data)
        : axios.post('/dpa/feeds/add', data),
    updateFeed: feed =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.put(`http://localhost:3030/api/dpa/feeds/${feed._id}`, feed)
        : axios.put(`/dpa/feeds/${feed._id}`, feed),
    publishFeed: feed =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.post('http://localhost:3030/api/dpa/feeds/publish', feed)
        : axios.post('/dpa/feeds/publish', feed),
    getFeedScreenshots: feedId =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.get(`http://localhost:3030/api/dpa/feeds/screenshots/${feedId}`)
        : axios.get(`/dpa/feeds/screenshots/${feedId}`),
    deleteFeed: feedId =>
      useLocalhostForSpecificEndPoint && window.location.hostname === 'localhost'
        ? axios.delete(`http://localhost:3030/api/dpa/feeds/${feedId}`)
        : axios.delete(`/dpa/feeds/${feedId}`),
  };

  dco = {
    getSetups: formData => axios.post('dco/setups/list', formData),
    getSetupsWithAdRefs: formData => axios.post('dco/setups/listWithAdRefs', formData),
    getSetup: id => axios.get(`dco/setups/${id}`),
    getAccessLog: data => axios.get(`dco/setups/accessLog/${data.relativeId}`),
    getChangeLog: data => axios.get(`dco/setups/changeLog/${data.relativeId}`),
    getAds: formData => axios.post('dco/ads/list', formData),
    getAdDiff: id => axios.get(`dco/ads/${id}/diff`),
    copySetupToBrand: formData => axios.post('dco/setups/duplicate', formData),
    createAd: formData => axios.post('dco/ads', formData),
    updateAd: formData => axios.put('dco/ads', formData),
    createSetupForm: formData => axios.post('dco/setups', formData),
    updateSetupForm: formData => axios.put('dco/setups', formData),
    updateLockedSetupForm: formData => axios.post('dco/setups/locked', formData),
    deleteSetupForm: (id, formData) => axios.delete(`dco/setups/${id}`, {data: formData}),
    downloadManifest: id => axios.get(`dco/setups/${id}/manifest`),
    publishAds: formData => axios.post('dco/ads/publish', formData),
    unpublishAds: formData => axios.post('dco/ads/unpublish', formData),
    createSharedPage: formData => axios.post('dco/preview', formData),
    deleteSharedPage: id => axios.delete(`dco/preview/${id}`),
    updateSharedPage: formData => axios.put(`dco/preview/${formData._id}`, formData),
    getSharedPages: formData => axios.post('dco/preview/list', formData),
    getSharedPageData: id => axios.get(`dco/preview/${id}`),
  };

  presets = {
    getPresets: brandId => axios.get(`/tools/smartCropper/presets/${brandId}`),
    updatePresets: (brandId, formData) =>
      axios.post(`/tools/smartCropper/presets/${brandId}`, formData),
  };

  tools = {
    json: {
      mutate: (jsonArray, count) =>
        axios.post('tools/json/mutate', {data: jsonArray, type: 'merge', count}),
    },
  };

  publicSharedPage = {
    addComment: (previewId, adId, commentData) =>
      axios.post(`dco/preview/${previewId}/comment/${adId}`, commentData),
  };
}

export default new Api();
